import { Link } from "gatsby";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const IndustryWhatWeDo = ({ WhatWeDo }) => {
	return (
		<section
			style={{
				background:
					"transparent linear-gradient(54deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
			}}
			className=" py-5 py-lg-7"
		>
			<Container>
				<Row className="align-items-center px-lg-4 g-5">
					{WhatWeDo.whatWeDoFields.servicesProvided.webDesign === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1  mb-4">
								<Link to="/web-design" className="ssp-bold white-link">
									WEB DESIGN
								</Link>
							</h2>
							<p className="text-white">
								Creating stylish, functional websites that your customers will
								love.
							</p>
						</Col>
					)}
					{WhatWeDo.whatWeDoFields.servicesProvided.webDevelopment === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1 text-white mb-4">
								<Link to="/web-development" className="ssp-bold white-link">
									WEB DEVELOPMENT
								</Link>
							</h2>
							<p className="text-white">
								Creating bespoke websites, incorporating all the elements your
								business needs.
							</p>
						</Col>
					)}
					{WhatWeDo.whatWeDoFields.servicesProvided.sustainableWeb === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1 text-white mb-4">
								<Link
									to="/sustainable-web-design"
									className="ssp-bold white-link"
								>
									SUSTAINABLE WEB
								</Link>
							</h2>
							<p className="text-white">
								Designing sustainable and accessible websites.
							</p>
						</Col>
					)}
					{WhatWeDo.whatWeDoFields.servicesProvided.googleAds === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1 text-white mb-4">
								<Link to="/google-ads" className="ssp-bold white-link">
									GOOGLE ADS
								</Link>
							</h2>
							<p className="text-white">
								Creating Google Ads that get you in front of your target
								audience and convert them into customers.
							</p>
						</Col>
					)}
					{WhatWeDo.whatWeDoFields.servicesProvided.eCommerce === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1 text-white mb-4">
								<Link to="/e-commerce-websites" className="ssp-bold white-link">
									E-COMMERCE
								</Link>
							</h2>
							<p className="text-white">
								Developing online shopping that enables your business and
								revenue to grow.
							</p>
						</Col>
					)}
					{WhatWeDo.whatWeDoFields.servicesProvided.seo === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1  mb-4">
								<Link to="/seo" className="ssp-bold white-link">
									SEO
								</Link>
							</h2>
							<p className="text-white">
								Developing an SEO strategy that lets your business be found by
								new customers.
							</p>
						</Col>
					)}
					{WhatWeDo.whatWeDoFields.servicesProvided.training === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1  mb-4">
								<Link to="/web-design" className="ssp-bold white-link">
									TRAINING
								</Link>
							</h2>
							<p className="text-white">
								Providing you with in-house SEO and Google Ads training.
							</p>
						</Col>
					)}
					{WhatWeDo.whatWeDoFields.servicesProvided.consultancy === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1 mb-4">
								<Link to="/contact-us" className="ssp-bold white-link">
									CONSULTANCY
								</Link>
							</h2>
							<p className="text-white">
								Bespoke in-house support from our expert team.
							</p>
						</Col>
					)}
					{WhatWeDo.whatWeDoFields.servicesProvided.branding === true && (
						<Col lg={4} className=" ">
							<h2 className="fs-1  mb-4">
								<Link to="/branding" className="ssp-bold white-link">
									BRANDING
								</Link>
							</h2>
							<p className="text-white">
								Branding advice and support to ensure your business is
								recognisable. 
							</p>
						</Col>
					)}
				</Row>
			</Container>
		</section>
	);
};

export default IndustryWhatWeDo;
