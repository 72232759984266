import { Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const IndustryImageSection = ({ heading, image, imageAlt, text1, text2 }) => {
	return (
		<section className="pt-5 pt-lg-7 bg-light-grey">
			<Container>
				<Row className="align-items-end">
					<Col lg={7}>
						<StaticImage
							className="mb-5"
							src="../images/Yellow-diamond.svg"
							placeholder="blurred"
							quality={100}
							style={{ width: "100px" }}
						/>
						<h2 className="display-5 pb-4 text-primary">{heading}</h2>
						<p className="pb-4">{text1}</p>
						<GatsbyImage
							className="w-100 "
							image={image}
							alt={imageAlt}
							objectPosition="top center"
						/>
					</Col>

					<Col lg={5}>
						<StaticImage
							className="mb-7"
							src="../images/Blue-diamond.svg"
							placeholder="blurred"
							quality={100}
							style={{ width: "100px" }}
						/>
						<p>{text2}</p>
						<Button
							className={`mt-4 mb-5 fs-5 w-100 d-none d-md-inline-block w-md-auto py-2 px-4 white-link`}
							variant="primary"
							as={Link}
							to="/contact-us"
						>
							GET IN TOUCH
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default IndustryImageSection;
