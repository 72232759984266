import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const IndustryResults = ({
	heading,
	image,
	imageAlt,
	text,
	imgHeight,
	last,
	bottomContent,
}) => {
	return (
		<section className="pb-5">
			<div className="pb-5 pb-lg-7 bg-light-grey">
				<Container className="pb-7">
					<Row>
						<Col lg={8}>
							<h2 className="display-5 pb-5 text-primary">{heading}</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<Row className="g-6">
								<Col
									className="d-none d-lg-block"
									lg={{ span: 4, order: last ? "last" : "first" }}
								>
									<div
										style={{
											borderRadius: "15px",
											boxShadow: "0px 3px 99px #0000006A",
											overflow: "hidden",
											maxHeight: imgHeight,
										}}
										className="w-100 "
									>
										<GatsbyImage
											className="w-100 "
											image={image}
											alt={imageAlt}
											objectPosition="top center"
										/>
									</div>
								</Col>
								<Col className="" lg={7}>
									{text}
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
			<div style={{ top: "-5rem" }} className="position-relative mt-7 ">
				<Container>
					<Row className="justify-content-center">
						<Col lg={10} xl={9}>
							<Row
								style={{ borderRadius: "15px" }}
								className="g-6 pb-6 bg-white"
							>
								<Col>{bottomContent}</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default IndustryResults;
